<div class="footer-ribbon" fxLayout="row" fxLayout=space-between>
  <div class="footer-column" fxLayout="column">
    <div class="col-header">About</div>
    <p class="col-text">San Sebastian is a friendly dressage training and boarding barn that offers premium care in a private setting about 30 minutes from downtown Seattle.</p>
  </div>
  <div class="footer-column" fxLayout="column">
    <div class="col-header">Site Links</div>
    <a routerLink="/home">Home</a>
    <a routerLink="/location">Location</a>
    <a routerLink="/services">Services</a>
    <a routerLink="/contact">Contact</a>
  </div>
  <div class="footer-column" fxLayout="column">
    <div class="col-header">Follow</div>
    <a href="https://www.facebook.com/sansebastianec" target="_blank">Facebook</a>
    <a>Instagram</a>
    <a>Twitter</a>
  </div>
</div>
